import React from "react"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import ArrowWhite from "../../assets/img/arrow-right.webp"



import { StaticImage } from "gatsby-plugin-image"

interface featuredImage extends IGatsbyImageData {
  publicURL: string
}
export interface ProjectsProps {
  idx: string
  title: string
  slug: string
  subTitle: string
  description: string
  clutchLink: string
  industry: string
  budget: string
  country: string
  featuredImage: featuredImage | undefined
  backgroundStyles: string

  showBudget?: boolean
  showProjectTechnologies?: boolean
  clutchButtonClass?: string
  navigateButtonType?: 1 | 2
}

const PortfolioDetail = ({ showBudget = true, showProjectTechnologies = false, clutchButtonClass = '', navigateButtonType = 1, ...project }: ProjectsProps) => {
  const image = getImage(project.featuredImage!)
  const isExternalLink = project.slug.startsWith('http')
  return (
    <div className={`${project.backgroundStyles} flex flex-wrap justify-between md:flex-nowrap md:pl-10 pl-[1.875rem] md:pt-12 pt-7 md:pb-0 pb-10 rounded-2xl mb-10 md:mb[3.125rem] pr-[0] md:gap-[30px] lg:gap-0`}>
      <div className="justify-center md:max-w-[40%] lg:max-w-[calc(40%-2.5rem)] md:pr-0 pr-[1.875rem] md:mb-0 mb-[3.125rem] w-full flex flex-col">
        <div className="pb-10 flex items-center justify-between">
          <h3 className="font-blancomedium text-xl md:text-2xl max-sm:max-w-[150px]">
            {project.title}
            <span className="inline-block text-orangecol">.</span>
          </h3>
          {project.clutchLink && <Link rel="nofollow" target="_blank" to={project.clutchLink}
            className={clutchButtonClass || "inline-flex items-center justify-start rounded-full px-[1.063rem] pt-[12px] pb-[13px] font-gorditamedium text-sm leading-none border border-1 border-white bg-gradient-to-l from-[#fff]"}
          >
            <StaticImage
              src="../../assets/img/star.png"
              alt=""
              placeholder="blurred"
            />
            <span className="mr-2 ml-1 relative top-[2px] leading-[15.11px]">5.0/5</span>
            <StaticImage
              src="../../assets/img/cluch.png"
              alt=""
              placeholder="blurred"
            />
          </Link>}
        </div>

        <div className="flex flex-wrap items-center justify-between md:flex-nowrap md:pb-10 lg:pb-0">
          <div className="max-w-full w-full flex items-center flex-wrap">
            <h3 className="text-2xl md:text-[30px] lg:text-4xl font-blancomedium">
              {project.subTitle}
            </h3>
            {!showBudget && (project.industry || project.country) && <ul className="flex flex-wrap mt-[1.5rem] w-full">
              <li className="text-sm pr-3 mr-3 relative after:content-[''] after:w-1 after:h-1 after:bg-black after:rounded-full after:absolute after:top-[7px] after:right-[-2px]" >
                {project.industry}
              </li>
              <li className="text-sm">
                {project.country}
              </li>
            </ul>}
            <p className="text-base leading-[1.7rem] block pb-5 pt-5 md:pt-7">
              {project.description}
            </p>
            {showBudget && (project.industry || project.country || project.budget) && <ul className="flex flex-wrap mb-10 w-full">
              <li className="text-sm pr-3 mr-3 relative after:content-[''] after:w-1 after:h-1 after:bg-black after:rounded-full after:absolute after:top-[7px] after:right-[-2px]" >
                {project.industry}
              </li>
              <li className="text-sm pr-3 mr-3 relative after:content-[''] after:w-1 after:h-1 after:bg-black after:rounded-full after:absolute after:top-[7px] after:right-[-2px]" >
                {project.budget}
              </li>
              <li className="text-sm">
                {project.country}
              </li>
            </ul>}
            {showProjectTechnologies && <ul className="flex flex-wrap gap-2 md:mb-6 w-full mb-4">
              <li className="text-[#5D5D5D] text-[11px] font-gorditamedium py-[7px] px-[10px] pt-[0.5rem] rounded border border-1 border-[rgba(0,0,0,0.14)] leading-[10px] text-center inline-block">ReactJS</li>
              <li className="text-[#5D5D5D] text-[11px] font-gorditamedium py-[7px] px-[10px] pt-[0.5rem] rounded border border-1 border-[rgba(0,0,0,0.14)] leading-[10px] text-center inline-block">NodeJS</li>
              <li className="text-[#5D5D5D] text-[11px] font-gorditamedium py-[7px] px-[10px] pt-[0.5rem] rounded border border-1 border-[rgba(0,0,0,0.14)] leading-[10px] text-center inline-block">SQL Server</li>
              <li className="text-[#5D5D5D] text-[11px] font-gorditamedium py-[7px] px-[10px] pt-[0.5rem] rounded border border-1 border-[rgba(0,0,0,0.14)] leading-[10px] text-center inline-block">Redis</li>
            </ul>}
            {navigateButtonType === 1
              ? (
                <Link
                  to={isExternalLink ? project.slug : `/portfolio${project.slug}/`}
                  target={isExternalLink ? '_blank' : '_self'}
                  className="inline-flex items-center bg-white-200 justify-start inline-white text-black text-base rounded-lg pl-6 pt-[12px] pb-[10px] mt-[16px] relative pr-[2.25rem] leading-[1.313rem] font-gorditamedium group ease-in duration-300 uppercase hover:bg-primary hover:text-white shadow-lg mb-8"
                >
                  Project Details
                  <span className="inline-flex justify-center items-center rounded-full ml-[0.625rem]  h-[2.125rem] w-[2.125rem] overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent absolute right-[5px] top-[5px]">
                    <img
                      src={ArrowWhite}
                      alt="ArrowRight"
                      className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-16 ease-in duration-300 w-[7px] h-[14px] invert-[1] brightness-[1]"
                    />
                    <img
                      src={ArrowWhite}
                      alt="ArrowRight"
                      className="absolute -left-16 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-1/2 w-[7px] h-[14px] ease-in duration-300"
                    />
                  </span>
                </Link>
              )
              : (
                <div
                  className="inline-flex items-center bg-white-200 justify-start inline-white text-black text-base rounded-3xl pl-6 pt-[12px] pb-[10px] relative pr-[52px] leading-[1.313rem] font-gorditamedium group ease-in duration-300"
                >
                  Project Details
                  <span className="inline-flex bg-black justify-center items-center rounded-full ml-[0.625rem]  h-[2.125rem] w-[2.125rem] overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent group-hover:border-black absolute right-[5px] top-[5px]">
                    <img
                      src={ArrowWhite}
                      alt="ArrowRight"
                      className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-16 ease-in duration-300 w-[7px] h-[14px]"
                    />
                    <img
                      src={ArrowWhite}
                      alt="ArrowRight"
                      className="absolute -left-16 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-1/2  group-hover:invert-[1] group-hover:brightness-[1] w-[7px] h-[14px] ease-in duration-300"
                    />
                  </span>
                </div>
              )
            }
          </div>
        </div>
      </div>
      <div className="md:max-w-[60%] lg:max-w-[calc(60%-2.5rem)] w-full flex flex-col justify-end items-center">
        <GatsbyImage
          image={image!}
          alt={`Techuz portfolio ${project.subTitle}`}
          className="mx-auto rounded-lg md:rounded-none"
        />
      </div>
    </div>
  )
}

export default PortfolioDetail
